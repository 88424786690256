export const CartSvg = () => (
  <svg
    version="1.1"
    id="cartSvg"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 550.5 441.2"
    style={{ enableBackground: 'new 0 0 550.5 441.2' }}
    xmlSpace="preserve"
  >
    <path
      d="M190.8,96.9c6.5-4.3,12.5-4.9,18.3-5.8c9.3-1.4,18.8-2.5,28.2-3c32.6-1.6,65.2-3.2,97.8-4c26.6-0.7,53.3-0.3,80-0.1
	c23.5,0.2,46.9,0.4,70.4,1.2c15.9,0.5,31.8,1.5,47.1,6.8c2.8,1,5.6,2.1,8.1,3.7c10.4,6.2,12.7,14.8,6.5,25.3
	c-5.3,9-11.3,17.5-16.4,26.6c-10.5,18.9-20.5,38.1-30.9,57.1c-10.8,19.9-21.7,39.7-32.7,59.5c-3.4,6.1-8.4,10.9-15.1,13.1
	c-5.6,1.8-11.5,2.8-17.4,3.7c-32.5,4.7-65.2,6-98,6.1c-26.5,0.1-53,0.8-79.4,0.8c-11.8,0-23.6-1.1-35.3-1.9
	c-8.8-0.6-17.6-0.9-26.3-2.1c-9.5-1.3-18.8-3.4-26.5-9.9c-5.5-4.7-8.7-10.8-11.1-17.3c-7.4-20.5-14.7-41-22-61.4
	c-5.4-15-11-30-16.1-45.1c-5.4-15.7-10.6-31.6-15.5-47.4C99.4,87,94.9,71.3,90.4,55.6c-1.3-4.3-2.1-8.7-3.4-12.9
	c-1.6-5.3-5.5-8.3-10.9-8.8c-12.7-1.2-25.5-1.8-38.2-3.1c-9-0.9-18-2.3-27-4C6.7,26,2.7,24,0.2,20.3c-0.8-3,1.1-4.4,3.2-5.7
	c7.1-4.2,15-6,22.9-7.7c4.5-0.9,9.1-1.6,13.7-2.1c10.4-1.1,20.8-2.1,31.2-2.9c9.1-0.8,18.2-1.3,27.3-1.9c12.9-0.8,23.6,4.7,28.7,17
	c7.5,17.9,14.4,36.1,21.6,54.1c4.9,12.4,10,24.7,14.9,37.1c7.7,19.3,15.5,38.6,23,57.9c5.3,13.7,10.3,27.5,15.5,41.2
	c4.3,11.1,11.5,19.4,23.6,22c5.2,1.1,10.6,1.3,15.9,1.5c20.8,0.8,41.6,2.1,62.4,2c37.5-0.1,75-1.1,112.4-1.7c5-0.1,10-0.2,15-0.3
	c3,0,5.6-0.9,7.3-3.5c8.7-12.9,17.7-25.6,25.8-38.9c11.1-18.2,21.5-36.9,32.1-55.4c1.9-3.3,3.2-7.1,4.9-11.1c-2.3-0.9-3.8-1.9-5.3-2
	c-12.4-1.1-24.7-1.9-37.1-2.9c-12.8-1-25.5-2.2-38.2-3.2c-15.9-1.3-31.8-2.5-47.7-3.7c-6.6-0.5-13.3-0.9-19.9-1.3
	c-20.2-1.3-40.5-3-60.7-3.8c-26.8-1.1-53.6-1.7-80.4-2c-5.8-0.1-11.6,1.1-16.8-2.2C194.1,99.9,192.9,98.6,190.8,96.9z"
    />
    <path
      d="M204,441.2c-22.2-1.8-39.6-10.6-48.5-32.1c-2.9-6.9-3.5-14.3-2.4-21.9c1.2-8.7,4.8-16.2,10.5-22.6
	c8.1-9.2,18.4-14.8,30.6-16.8c13.4-2.2,26-0.4,37.9,6.1c14.8,8,26.2,27.4,23.2,46.8c-3.1,19.9-22.3,35.7-40.1,39.1
	C211.6,440.6,207.8,440.8,204,441.2z M196.8,361.1c-3.4,1.1-7.1,1.7-10.2,3.4c-12.9,7-12,22.7-3,29.3c5.4,3.9,11.4,5.7,18.2,4.1
	c13.1-3.1,19.4-17.6,11.8-27.7C209.6,364.7,203.9,361.9,196.8,361.1z"
    />
    <path
      d="M464.5,441c-24.2-0.3-46.1-16-50.7-39.4c-3.1-15.8,2.4-29,13.7-40.1c5.9-5.8,12.8-10.3,20.8-12.2c6.8-1.7,14.1-2.2,21.2-2.1
	c10.1,0.2,19.4,3.7,27.6,9.8c12.2,9.2,19.5,21.3,19.6,36.8c0.1,13.1-5.4,24.1-15.1,32.9C491.3,436.1,478.4,441.1,464.5,441z
	 M457.7,361.8c-4.7-0.1-8.8,1.3-12.6,3.8c-5.8,3.9-9.4,11.9-7.9,17.5c3.4,12.4,16.2,17.4,25.6,14.6c12.9-3.9,18.9-16.6,11.5-27.5
	C470.3,364.5,464.5,362.1,457.7,361.8z"
    />
    <path
      d="M412.6,371.2c-9-10.1-20.2-15.7-31.8-20.1c-13.3-5-26.7-9.7-40.3-13.5c-14.5-4-29-8.2-44.3-7.7c-6.7,0.2-13.2,1.4-18.2,6.5
	c-3.4,3.5-4.7,7.5-3.4,12.3c1.1,3.8,2.2,7.6,3.3,11.5c-1.9,5.5-6.2,7.8-13.3,5.4c-9.7-3.2-18.8-8.3-24.7-16.8
	c-11.6-16.5-4.6-33.2,10.4-41.7c7.4-4.2,15.6-5.9,24.3-6.3c22.4-1,43.7,3.7,64.6,11.1c19.5,6.9,37.7,16.2,54.2,28.5
	c8.8,6.6,16.2,14.8,22.4,24c0.9,1.3,1.1,3.6,0.6,5C416,370.4,413.8,370.7,412.6,371.2z"
    />
  </svg>
)
