export const LogoSvg = () => (
  <svg
    id="logoSvg"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 833.4 189.6"
    style={{
      enableBackground: 'new 0 0 833.4 189.6',
    }}
    xmlSpace="preserve"
  >
    <style>{'.st0{fill:#353535}.st1{fill:#fed137}.st2{fill:#547944}'}</style>
    <path
      className="st0"
      d="M833.4,189.6c-278,0-555.6,0-833.4,0C0,126.4,0,63.3,0,0c277.7,0,555.4,0,833.4,0
	C833.4,63.1,833.4,126.2,833.4,189.6z"
    />
    <g>
      <path
        className="st1"
        d="M8.1,7c0,0,11.7-0.1,17.5-0.1c186,0,371.9,0,557.9,0c1.6,0,3.3,0.1,5.2,0.2c0,58.2,0,116.1,0,174.7
		c-1.9,0-3.7,0-5.5,0c-189.8,0-379.6,0-569.4,0c-2.1,0-6.2,0-6.2,0 M244,148.8c11.5,0,22.5,0,33.7,0c13.9-35.7,27.7-71.3,41.5-106.8
		c0.4,0.1,0.7,0.1,1.1,0.2c0,35.5,0,71,0,106.4c13.5,0,26.4,0,39.8,0c0-35.9,0-71.4,0-106.9c0.3-0.1,0.6-0.1,0.9-0.2
		c13.9,35.8,27.8,71.5,41.6,107.2c11.5,0,22.6,0,33.7,0c13.6-35,27.1-69.8,40.7-104.5c0.5,0.1,0.9,0.2,1.4,0.3c0,34.7,0,69.4,0,104
		c25.1,0,49.7,0,74.1,0c0-10,0-19.5,0-29.6c-11.5,0-22.6,0-33.8,0c0-3.6,0-6.7,0-10.4c10.1,0,19.8,0,29.7,0c0-9.8,0-18.9,0-28.6
		c-10.2,0-19.9,0-29.9,0c0-3.5,0-6.6,0-10.2c11.2,0,22.1,0,33.1,0c0-10.1,0-19.7,0-29.6c-38.3,0-76.4,0-114.6,0
		c-6.1,21.3-12.1,42.4-17.5,61.4C414,82.4,408,61.2,402,40.2c-41.6,0-82.4,0-123.7,0c-6.1,21.5-12.2,43-17.5,61.7
		c-5.3-18.8-11.4-40.3-17.4-61.7c-14,0-27.3,0-41.5,0C216.1,76.7,230,112.7,244,148.8z M156.9,38.4c-22.8-0.7-49.3,12.8-57.4,38.3
		c-3.7,11.5-3.6,23.3-1.8,34.5c1.1,7,6.7,13.9,11.8,19.5c18,19.6,40.8,23.9,65.8,17.7c19.2-4.8,33.4-16.2,39.3-36
		c5-16.4,3.5-32.4-5.6-47C197,46.3,178.6,39,156.9,38.4z M38.3,110.7c0,12.9,0,24.2,0,35.9c12.8,5.7,25.7,6.3,38.2-0.8
		c12-6.7,17.4-17.6,17.5-31.1c0.2-23.8,0.1-47.7,0-71.5c0-1-0.2-1.9-0.3-2.9c-12.8,0-25.4,0-38.2,0c0,20.7,0,41,0,61.4
		c0,3-0.1,6-0.7,8.9c-1.2,5.8-6.1,7.6-11,4.2C42.4,113.8,41,112.7,38.3,110.7z"
      />
      <path
        className="st1"
        d="M136.7,95.4c0.1-13,7.9-20.9,20.4-20.8c12.3,0.1,20.1,8.2,20,21.1c0,12.5-8.4,20.9-20.6,20.8
		C144.8,116.3,136.6,107.6,136.7,95.4z"
      />
    </g>
    <circle className="st1" cx="755" cy="73.2" r="41.9" />
    <polygon className="st2" points="624.9,35.2 795.4,153.4 625.1,153.4 " />
  </svg>
)
