export const EditSvg = () => {
  return (
    <svg
      id="editSvg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 267.8 335.8"
    >
      <path
        d="M222.8,247.4c-1.3-1-2.6-2-3.9-2.9c-15.4-11.4-31.2-12.1-47.5-2.2c-12,7.3-21.2,17.5-30.7,27.7
            c-14,15.1-27.7,30.6-42.7,44.7c-13.3,12.5-29.7,19.7-48.2,20.9c-15.6,1-29.8-2.6-41.1-14.2c-7-7.1-10-15.8-8.2-25.6
            c0.6-3.6,2.6-6.9,4.7-10.2c-4.2,10-3,19.3,3.4,27.7c6.3,8.3,15.3,12.2,25.3,13.7c18.3,2.6,35.1-2.5,49.3-13.7
            c12.2-9.6,23.4-20.7,34.3-31.9c12.9-13.2,24.2-28.1,39.6-38.7c6.1-4.2,12.8-7.9,19.8-10.3C194.7,226.4,211.2,231.9,222.8,247.4z"
      />
      <path
        d="M267.8,50.1c-5.2,13.8-15,24.6-25,35.6c-14.5,16.2-28.2,32.5-42.2,48.7c-27.9,30.5-83,101.5-96.6,106.9
            c-9,4.7-18.9,6.3-28.6,9.5C-2.6,272.8,6.2,280,25,220.5c6.9-22,11.9-45.1,26-63c27.1-33.7,61.9-60.6,89.9-93.5
            c42.2-44.4,53-82.8,91.7-54C247.7,20.5,261.7,31.7,267.8,50.1z M87.1,236.7c-4.2-8.3-11-15.1-12.7-23.9c7.8,3.8,9.8,15.8,16.1,22.2
            c16.6,2.2-13.2-26.4-7.3-31.1c6.7,5.5,10.8,19.3,16.7,27.3c8-2.8,3.1-10.1,2.1-16c-6.9-20.8-24.7-37.7-47.2-41.4
            c-6.6,0.4-6.1,12-9,17.1c-4.2,12.7-8.2,24.9-12.3,37.7c2.3,6.1,11.7,12.9,16,18.7c8.5,1.3,8.2-2.8,4.9-9.5c-0.4-6.2,4.8,3.1,6.7,6.1
            c0.8,1.2,6.5,0.7,4.6-2.1c-1.7-4.4-5.1-6.7-3.7-11.8c6.6,3,4.8,17.4,14.8,9.4c-2.2-5.3-14-19.6-8.2-21
            C79.5,235.1,77.4,242,87.1,236.7z M199.3,69.6C71.1,210.6,97.7,187.1,103.9,202.1C229.3,54.3,212.8,86.1,199.3,69.6z M180.9,55.8
            c-33.8,40.4-67.2,80.3-100.8,120.5c3.5,2.8,7,5.5,10.8,8.6c31.6-42.2,67.2-80.7,103.3-119.4C189.4,62,185.3,59,180.9,55.8z
            M215.3,84.1c-125.8,144.2-107.9,120.7-104,138.7C241.6,69.7,225.9,99.5,215.3,84.1z M237.5,75.7c4.8-2.8-5-5.8-4.4-10.4
            c3.2,0,5.9,3.1,8.8,5.5c-1.3-5-6.8-7.2-6.5-13c3.8,2.3,6.2,6.7,11.1,8.4c-0.7-5.8-14.8-13.1-9.8-16.5c2,2.1,18.5,18.8,12.8,8.7
            c-4.2-5.3-12.9-10.6-8.6-15.3c4,5,7.7,9.6,11.5,14.5c7.6-7.7-5-11.2-8.8-16.6c-2.2-1.5-5.6-6.7,0.6-3.8c4.1,2.3,6.4,6.4,10.9,8.9
            c-5.9-11.2-17.5-19.9-27.7-27.2c-23.4-17.5-27.8-5.2-43.3,8.8C205,41.5,222.2,57.5,237.5,75.7z M56,166.5
            c9.3,1.4,16.3,10.2,22.5,0.5C111,127.9,142.8,91.1,174.7,52c-2.9-0.7-5.6-6.3-8.2-1.6c-7.7,9.3-15.2,16.6-23.7,25.4
            C113.5,106.5,83.8,134.4,56,166.5z M180.3,33.5c-2.6,3.3-4.8,6.1-7.4,9.3c20.7,12.2,38.5,26.9,54.6,44.2c2.1-2.4,3.6-4.3,5.2-6.2
            C217.5,62.3,200.5,46.7,180.3,33.5z"
      />
    </svg>
  )
}
