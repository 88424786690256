export const BackOfficeSvg = () => (
  <svg
    version="1.1"
    id="backOfficeSvg"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 460 460"
    style={{ enableBackground: 'new 0 0 460 460' }}
    xmlSpace="preserve"
  >
    <path
      d="M366.7,415.3c-3.1-1.5-5.6-2.4-7.7-3.8c-14.7-10.2-29.4-20.5-44-31c-2.9-2.1-5-2.4-8.4-0.8c-8.3,4-17,7.4-25.6,10.8
	c-2.4,0.9-3.7,1.7-4.2,4.6c-3.1,18.3-6.5,36.5-9.7,54.8c-1.2,6.7-4.7,10.1-11.4,10.1c-17.5,0.1-35,0.1-52.5,0
	c-6.3,0-10.1-3.6-11.1-9.9c-3-18.5-6-37-9.2-55.4c-0.2-1.4-1.9-3-3.2-3.6c-9.4-4.1-18.8-8.1-28.4-11.8c-1.5-0.6-4.1,0-5.6,1
	c-14.9,10.3-29.7,20.7-44.6,31.1c-6.2,4.3-10.6,4-15.9-1.3c-11.9-11.9-23.8-23.8-35.7-35.7c-5.8-5.9-6.1-9.5-1.4-16.2
	c10.4-14.6,20.7-29.3,31.3-43.8c1.9-2.6,1.8-4.4,0.5-7.2c-3.8-8.6-7.4-17.3-10.8-26.1c-1-2.5-1.9-4-4.9-4.5
	c-18-3.1-35.8-6.4-53.8-9.6c-7-1.2-10.3-4.6-10.3-11.7c-0.1-17.3-0.1-34.7,0-52c0-6.6,3.6-10.3,10.1-11.4c18.3-3,36.6-5.9,54.9-9.1
	c1.4-0.2,3-1.9,3.6-3.3c4.1-9.4,8.1-18.9,11.7-28.4c0.6-1.6-0.1-4.4-1.2-6c-10-14.7-20.3-29.2-30.5-43.8c-4.7-6.7-4.3-10.9,1.4-16.7
	C62,72.9,73.8,61.1,85.6,49.4c5.8-5.7,9.5-6,16.2-1.2c14.7,10.4,29.3,20.7,43.8,31.3c2.5,1.8,4.2,2,7,0.7
	c8.6-3.9,17.3-7.4,26.1-10.8c2.5-1,3.9-1.7,4.4-4.9c2.7-18.2,5.9-36.3,8.8-54.5c1-6.2,4.8-9.9,11.1-9.9c17.7-0.1,35.3-0.1,53,0
	c6.1,0,9.9,3.6,10.9,9.5c3,17.8,6.1,35.6,9.1,53.4c0.6,3.7,2,5.8,6.1,7.2c8,2.6,15.8,5.9,23.4,9.7c4,2,6.6,1.6,10.1-0.9
	c14.2-10.5,28.5-20.7,42.9-30.9c6.2-4.4,10.1-4.2,15.4,1.1c12.2,12.1,24.3,24.2,36.4,36.4c5,5,5.3,9.7,1.2,15.6
	c-10.4,14.8-20.8,29.7-31.3,44.4c-1.9,2.7-2.2,4.8-0.7,8c3.9,8.2,7.1,16.7,10.4,25.2c1,2.7,2,4.1,5.4,4.6
	c18.4,2.7,36.6,5.8,54.9,8.9c6.2,1,9.6,4.9,9.7,11.4c0.1,17.5,0.1,35,0,52.5c0,6.4-3.4,9.9-9.9,11.1c-18.4,3.3-36.9,6.5-55.2,10
	c-1.7,0.3-3.7,2-4.4,3.5c-4,9.1-7.8,18.2-11.3,27.5c-0.6,1.6-0.1,4.2,0.9,5.6c10.5,15,21.2,29.8,31.9,44.6
	c4.7,6.6,4.5,10.4-1.2,16.2c-11.8,11.9-23.7,23.9-35.7,35.7C372.6,412.2,369.5,413.5,366.7,415.3z M20.1,248.8
	c17.4,3.1,34.5,6.3,51.7,9.1c6.7,1.1,10.9,3.7,13.3,10.6c4.4,12.4,9.5,24.6,15.1,36.6c2.6,5.6,3,10-0.8,15.1
	c-10.7,14.6-21.1,29.4-31.3,43.7c9.2,9.2,18.1,18.2,27.2,27.3c0.3-0.2,1.4-0.7,2.3-1.4c14.3-10,28.7-19.9,42.9-30
	c4.3-3.1,8.3-3.6,12.9-0.9c12.3,7.3,25.4,12.8,39.4,16.2c4.4,1.1,7.4,3.8,8.2,8.4c1.1,6.2,2,12.4,3.1,18.6c2.1,12.5,4.2,25,6.3,37.6
	c13,0,25.7,0,38.5,0c3.4-18.7,6.8-37.3,10-55.9c0.8-4.7,3.4-7.6,7.7-8.7c14.1-3.6,27.3-9.2,39.8-16.5c4.4-2.6,8.5-2.3,12.5,0.7
	c5.5,4,11.1,7.9,16.7,11.8c9.9,6.9,19.8,13.8,29.8,20.8c8.6-8.6,16.9-16.8,25-25.2c0.8-0.8,0.7-3.7-0.1-4.8
	c-10.2-14.4-20.5-28.7-30.8-43c-2.8-3.9-3.6-7.9-1.2-12.1c7.5-12.8,13.1-26.4,16.8-40.7c1-4,3.8-6.5,8-7.2c5.6-0.9,11.1-2,16.6-2.9
	c13.3-2.4,26.7-4.7,39.9-7.1c0-13.2,0-25.9,0-38.5c-18.5-3.1-36.6-6.3-54.8-9c-6-0.9-8.5-4.3-10.5-9.6
	c-4.8-12.6-10.1-25.1-15.6-37.5c-2.2-4.9-2.4-9,0.8-13.4c4.1-5.5,7.9-11.1,11.8-16.7c6.8-9.8,13.7-19.5,19.9-28.4
	c-9.3-9.4-18.2-18.3-27.3-27.5c-14.6,10.5-29.6,21.2-44.5,32c-4.4,3.2-8.6,3.8-13.4,0.9c-12.3-7.3-25.4-12.8-39.3-16.3
	c-4.3-1.1-7.2-3.8-7.9-8.6c-0.7-5.2-1.9-10.4-2.8-15.6c-2.4-13.5-4.8-27-7.2-40.4c-13.1,0-25.7,0-38.4,0c-3.1,18.5-6.3,36.7-9.1,55
	c-0.9,5.7-4.3,7.9-9.2,9.8c-12.5,4.7-25,9.6-37.1,15.2c-5.9,2.7-10.3,2.8-15.5-1.1c-13.5-10-27.3-19.6-41-29.2
	c-1.2-0.8-3.9-1.6-4.5-1c-8.7,8.4-17.2,17-25.7,25.6c0.8,1.2,1.2,2.1,1.8,2.9c10,14.3,20,28.6,30,42.9c2.7,3.8,3.8,7.7,1.3,12
	c-7.7,13.1-13.2,27-17.5,41.5c-0.8,2.8-4.5,6-7.5,6.8c-8,2.1-16.3,2.9-24.4,4.3c-10.6,1.8-21.1,3.6-31.7,5.4
	C20.1,223.3,20.1,235.8,20.1,248.8z"
    />
    <path
      d="M310,230.4c-1.1,44.8-35.9,79.7-80.5,79.4c-45.1-0.4-79.6-35.7-79.3-80.4c0.3-44.7,35.9-79.6,80.5-79.2
	C275.2,150.6,309.3,185.9,310,230.4z M230.1,170c-32.3-0.8-59.5,26.6-60.2,58.5c-0.8,34.9,28.7,61.3,59.3,61.7
	c33.6,0.4,60.7-27.6,61-59.4C290.5,197.6,262.6,169.2,230.1,170z"
    />
  </svg>
)
