export const DeleteSvg = () => {
  return (
    <svg
      id="deleteSvg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 400 544"
    >
      <path
        d="M144.6,33.7c1.9-20.4,12.5-30.9,32.6-33c18.1-1.9,35.9-0.1,53.5,4.3c11.2,2.8,17.8,10.5,21.2,21.3c0.6,1.9,0.9,3.8,1.4,5.9
            c6.3,0,12.4,0.2,18.6,0c22.5-0.8,44.9-1.8,67.4-2.6c12.2-0.5,24.3,0.3,36,4.6c16.6,6.1,25.6,18,24.7,33
            c-0.7,10.6-5.9,18.9-14.2,25.4c-2,1.6-3.1,2.7-2.1,5.7c0.9,2.4,0.5,5.3,0.4,7.9c-2.8,82.4-5.1,164.9-8.6,247.3
            c-1.6,38.4-4.9,76.8-8.6,115.1c-4.1,43-30.9,64.4-68.8,70c-23,3.4-46.4,4.3-69.7,5c-30.5,0.9-61,0.9-91.4,0.2
            c-12.5-0.3-25.1-3-37.4-5.8c-35.2-7.9-56.7-30.4-66.7-64.6c-5.7-19.7-7.6-39.9-8.6-60.2c-4.5-90-8.8-180.1-12.8-270.1
            c-0.7-14.8,0.4-29.6,0.4-44.5c0-2.1-1.1-4.7-2.7-6.1c-6.1-5.7-7.6-13.1-8.7-20.7c-2.1-14.5,2.9-26.2,14.4-35
            c1.7-1.3,4.3-1.8,6.5-1.9c8.3-0.2,16.7-0.1,25-0.2c31.2-0.2,62.3-0.3,93.5-0.5C141.4,33.9,142.8,33.8,144.6,33.7z M27.2,99.1
            c0.6,4.6,1.5,8.8,1.7,13.1c2,30.1,4.1,60.2,5.8,90.3c3.9,70.7,7.5,141.4,11.4,212.1c1,17.6,2.9,35.1,7.7,52.3
            c7.8,27.9,25.7,45.3,54,50.6c15.6,2.9,31.7,4.6,47.6,4.9c28.3,0.6,56.7,0.2,85-0.8c19.1-0.7,38.2-2.6,57.2-5.1
            c17.8-2.3,32.2-11,42.1-26.6c5.6-8.9,7.7-18.8,8.4-29c3.1-44.7,6.7-89.4,8.7-134.2c3-65.9,4.7-131.9,7-197.8
            c0.2-7.2,0.3-14.3,0.4-21.5c0.1-3.7-1.1-5.4-5.3-4.8c-5.6,0.7-11.3,1-16.9,1c-38.8,0.5-77.7,1.5-116.5,1.2
            c-52.8-0.4-105.6-1.7-158.4-3C53.9,101.6,40.8,100.1,27.2,99.1z M165.6,53.9c-43.5,0-92.3,0.1-141.1-0.1c-4.5,0-6.2,1.8-6,5.7
            c0.2,4.5,0.9,8.9,1.5,13.3c0.4,3.3,2.4,4.8,5.7,5.1c13.1,1.2,26.2,3.1,39.3,3.7c96.6,4.1,193.2,3.4,289.8,1.2
            c7.3-0.2,13.7-2.8,19.4-7.1c9.4-7.2,8.5-17.1-2.5-21.8c-5.7-2.4-12.2-3.9-18.4-4c-13.5-0.3-26.9,0.6-40.4,0.9
            C265.5,51.8,218.2,52.8,165.6,53.9z M231.9,33.1c-1.4-7.5-3.3-9.4-9.2-10.5c-4.6-0.8-9.1-2.1-13.8-2.4c-11.6-0.7-23.2-1.8-34.8-1.2
            c-8.3,0.4-13.2,6.7-14,14.2C184,33.1,207.7,33.1,231.9,33.1z"
      />
      <path
        d="M331.8,277.5c-1.6,39.3-2.9,78.6-5,117.8c-1.3,23.9-3.7,47.8-5.8,71.7c-0.3,3.1-1.3,6.2-2.4,9.1
            c-3.9,10-12.1,14.7-22.7,12.6c-5.5-1.1-10.9-3.1-16.1-5.2c-4-1.6-6-5.1-5.8-9.6c1-17.1,2.4-34.2,2.8-51.3
            c1.6-56.8,2.8-113.6,4.2-170.3c0.6-23,1.1-45.9,1.9-68.9c0.3-9.6,1.6-19.3,1.8-28.9c0.5-24.5,19.8-29,38-20
            c10.5,5.2,13.3,14.1,13.1,24.2c-0.5,26-1.5,51.9-2.5,77.9c-0.5,13.6-1.3,27.3-2,40.9C331.6,277.5,331.7,277.5,331.8,277.5z
            M301.5,307.9c-0.1,0-0.3,0-0.4,0c-1,41.6-1.9,83.2-3,124.9c-0.3,11.1-1.1,22.3-1.5,33.4c0,1.1,1.1,2.3,1.8,3.5
            c0.9-0.9,2.1-1.6,2.7-2.7c0.7-1.3,0.9-2.9,1-4.3c2.3-28.5,5.3-57,6.7-85.6c2.6-53.2,4.1-106.5,6.5-159.7c1-20.9,3-41.8,5.1-62.7
            c1.2-11.3,1.4-11.7-9.9-11.2c-6.9,0.3-7.4,0.9-7.4,8.1c0,13.6,0.1,27.3-0.1,40.9C302.6,231,302,269.4,301.5,307.9z"
      />
      <path
        d="M112.8,319.1c0.7,36.6,1.3,73.3,2.3,109.9c0.4,15,1.8,29.9,2.8,44.8c0.3,4.5-1.6,8.1-5.7,9.7c-5.5,2.2-11.3,4.2-17.1,5.3
            c-10.6,1.8-19-3.2-21.8-13.6c-2.2-8-3.1-16.3-3.9-24.6c-5-54.1-6.8-108.4-8.5-162.6c-1-31.3-3.2-62.5-4.7-93.8
            c-0.5-9.8-0.4-19.7-0.5-29.5c0-2.8,0.3-5.7,0.3-8.5c0.2-20,19.4-27.4,33.4-25.8c9.3,1,15.2,6.6,16.5,16.1c1.6,11.7,2.6,23.5,3,35.3
            c1.3,41.8,2.1,83.6,3.1,125.4c0.1,4,0,8,0,12C112.3,319.1,112.5,319.1,112.8,319.1z M79.4,143.4c-8.6-0.2-9.1,0.2-8.2,8.8
            c0.7,6.4,2.3,12.8,2.7,19.3c1.8,29.4,3.4,58.9,4.8,88.3c3,62.4,3.9,124.9,9.5,187.2c0.6,6.5,1.2,12.9,2.2,19.3
            c0.2,1.3,2.1,2.3,3.3,3.5c0.6-1.3,1.6-2.7,1.6-4c-0.7-26.8-1.7-53.6-2.3-80.4c-1.7-71-3.2-141.9-4.8-212.9c-0.1-6.2,0-12.3,0.5-18.5
            C89.4,144.6,88.8,143.6,79.4,143.4z"
      />
      <path
        d="M224.5,314.1c0,47,0,94,0,140.9c0,3.5,0.2,7,0.3,10.5c0.4,9.9-4.3,16.5-13.9,19.6c-17.4,5.6-35.1-5.9-36.3-24.2
            c-1.4-22.4-2.9-44.9-2.9-67.4c0-40.6-3.6-80.9-6.2-121.3c-2.2-33.2-3.8-66.5-5.7-99.8c-0.1-1.3,0.1-2.7,0-4
            c-3-22.5,16.3-34.6,31.8-38.1c13-2.9,23.8,3.8,27.2,16.6c1.5,5.6,2,11.5,2.1,17.3c1,50,1.7,99.9,2.6,149.9
            C223.8,314.1,224.2,314.1,224.5,314.1z M203.9,466.2c0-12.4,0.1-24.8,0-37.3c-0.6-88.6-1.1-177.2-2-265.9
            c-0.1-15.8-0.2-18.3-19-15.2c-0.8,0.1-1.6,0.4-2.4,0.6c-3.1,0.7-4.3,2.4-4,5.7c1,9.1,1.8,18.2,2.4,27.4c1.9,28.8,3.7,57.5,5.6,86.3
            c2.1,32.7,4.6,65.5,6.2,98.2c1.5,30.8,2,61.6,3,92.4C194,464.8,197.3,467.3,203.9,466.2z"
      />
    </svg>
  )
}
