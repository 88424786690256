export const ConfirmSvg = () => {
  return (
    <svg
      id="confirmSvg"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 312.2 313.2"
    >
      <path
        d="M317.7,142.3c2.5,8.2,2.5,16.4,0.4,24.7c-3,11.7-5.2,23.6-9.4,34.9c-5.7,15.4-14.2,29.4-25.8,41.1
            c-14.1,14.2-28.5,28-42.7,42.1c-3.3,3.3-6.4,6.9-9.9,10.1c-2.1,1.9-4.5,3.5-6.9,5c-5.2,3.1-10.3,6.6-15.9,8.9
            c-5.8,2.4-12,4-18.2,5.2c-6,1.2-12.2,1.5-18.4,2c-6.1,0.5-12.3,1.2-18.5,1.1c-13.8-0.4-27.3-2.9-39.8-8.6
            c-6.9-3.1-13.4-7.2-19.7-11.4c-8.1-5.5-16.2-11.1-23.7-17.4c-7.5-6.3-14.9-12.9-21-20.5c-8.5-10.5-16-21.8-23.3-33.1
            c-6.8-10.6-12.6-21.8-16.2-34.1c-1.4-5-4.2-9.6-5.4-14.6c-1.4-5.7-1.9-11.7-2.6-17.6c-0.5-4.7-0.9-9.4-0.7-14.1
            c0.2-5.7,0.9-11.4,1.8-17c1.9-10.9,4.7-21.6,9.7-31.6c9-18.2,19.9-35,35.6-48.4c9.9-8.4,20.7-14.9,32.3-20.4
            c6.8-3.3,13.5-6.7,20.3-9.8c3.6-1.7,7.3-3.2,11-4.4c11.2-3.6,22.6-6.9,33.8-10.6c12.9-4.3,26-4.6,39-2c15.1,3,29.6,7.9,43.6,14.7
            c9.1,4.4,17.3,9.8,24.7,16.4c6.6,5.8,13.1,11.9,18.4,18.8c7.5,9.7,14.3,20,20.5,30.6c5.3,9.1,9.2,18.9,13.6,28.4
            c3.4,7.4,6.8,14.8,10.1,22.3C315.8,135.8,316.6,139.1,317.7,142.3z M34.2,151.9c-2.1,17.8,1.9,34.9,5.9,51.9c2,8.2,5.4,16,7,24.2
            c3.2,15.5,13.8,25.9,24.7,35.6c7.7,6.9,17.3,12.2,26.8,16.4c9.1,3.9,19.1,6.9,29,7.6c16.6,1.2,33.1,0.3,49.6-3.9
            c17.2-4.4,33.7-10,49.5-18.1c7.2-3.7,13.6-8.7,20.6-12.7c8.5-4.9,15.1-12,21.1-19.3c9.4-11.5,17-24.2,20.1-39
            c1-4.8,1.9-9.7,2.2-14.6c0.2-2.7-1.1-5.5-1.6-8.3c-1.6-9.4-2.5-18.9-4.9-28.1c-4.9-19.1-12.5-37.1-23.6-53.6
            c-11.3-16.9-25.5-31.2-40.1-45.2c-2.9-2.8-7-5.4-10.9-6.1c-10.7-1.8-21.6-2.9-32.4-3.7c-8-0.6-16-0.1-24-0.1c-1.4,0-2.7,0.1-4.1,0.3
            c-7.1,1-14.1,2.6-21.2,3c-15.3,0.8-29.3,5.6-42,13.7c-14,8.8-25.7,20.3-35.2,33.9c-10.4,14.8-16.9,31.1-16.5,49.6
            C34.3,140.9,34.2,146.4,34.2,151.9z"
      />
      <path
        className="st0"
        d="M100.1,235c9.7-14.5,19.4-28.9,29-43.2c24.3-36.2,48.4-72.5,72.8-108.6c3.2-4.8,7.3-9.2,11.5-13.2
            c5-4.6,8.5-3.2,9.8,3.3c0.9,4,1.1,8-0.9,11.8c-27.7,53.3-57,105.7-92.9,154.3c-4.9,6.6-10.9,12.5-16.6,18.6
            c-5.5,5.9-12.4,6.8-19.8,3.8c-7.4-2.9-11.9-8.2-13.1-16c-1.8-11.5-3.5-22.9-5.2-34.4c-0.9-6.3-0.4-12.5,2.3-18.4
            c2.1-4.6,5.7-7,10.8-7.2c3.7-0.1,5,1,4.8,4.6c-0.4,15.1,3.1,29.5,6.8,43.9C99.5,234.5,99.8,234.6,100.1,235z"
      />
    </svg>
  )
}
