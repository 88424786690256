export const ContactSvg = () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 540.5 419.4"
    style={{
      enableBackground: 'new 0 0 540.5 419.4',
    }}
    xmlSpace="preserve"
  >
    <path
      d="M270.5,0c77.5,0,155-0.1,232.6,0c26.2,0,37.2,11.5,37.2,39.8c0.2,113.4,0.2,226.8,0,340.2c-4,27.8-12,32.8-37.8,39.3
	c-155.7,0.1-311.5,0-467.2-0.1c-23.5,0-35-11.8-35.1-37.5C-0.1,266.9-0.1,151.9,0.2,37C0.3,11.7,12.2,0.1,35.8,0.1
	C114.1,0,192.3,0.1,270.5,0z M274.9,386.4c86.5,2.5,130.8,2.5,205.2,2.5c15.3,0,26.4-15.7,27.7-32.7c9.1-116.6,9.1-204.8,4.4-304
	c0.1-17.1-4.1-21-19.4-21.6c-143.2-5-281.4-4.6-428.4,4.5c-15.3,0.9-21,6.5-21,17.1c0,105.1,0.4,191.5,5.4,298.3
	c0.5,9.9,4.6,29,15.7,29C140.8,385.1,203.9,386.4,274.9,386.4z"
    />
    <path
      d="M79.7,45.2c10.1,0,19,15,24.8,21.2c51.5,55,103.2,109.9,153.9,165.8c11.1,12.2,16.8,12.1,27.8,0
	c49.7-54.8,73.7-74.1,124.1-128.1c5.4-5.8,39-38.5,45.8-41.4c4.2-1.8,19.1-14.4,22.8-10.6c2.5,2.6,2,10.6,0.3,15.3
	c-1.7,4.6-79.2,93.3-82.7,97c-53.7,58.1-36.7,38.9-90.5,96.9c-21.2,22.9-61.7,32-82.8,9.4C168,211,126.9,152.4,71.9,92.4
	c-4.3-4.7-5.1-17.2-5.4-23.7C66.3,63.5,76.5,52,79.7,45.2z"
    />
  </svg>
)
