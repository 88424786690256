import React from 'react'
import { Link } from 'react-router-dom'

import ProductCard from './productCard'
import ProductMiniCard from './ProductMiniCard'
import { addToCart } from '../../atoms/Services/cartServices'

export const ProductGrid = ({
  origin,
  products,
  className,
  category,
  showMiniCard,
  productId,
  setProductMessage,
  setProductName,
  setShowContent,
}) => {
  const linkStyle = {
    color: 'inherit',
    textDecoration: 'inherit',
  }

  const productByCategory = products.filter(
    (product) => product._category === category && product._id !== productId
  )

  return (
    <>
      <div className={`${className}`}>
        {productByCategory.map((product, index) => {
          const handleAddToCart = () => {
            addToCart(product._id, 1)
          }

          const handleOut = () => {
            if (origin === 'home') {
              setProductMessage('')
              setProductName('')
              setShowContent('')
            }
          }

          const handleHover = () => {
            if (origin === 'home') {
              setProductMessage(product._shortDescription)
              setProductName(product._name)
              setShowContent('showContent')
            }
          }

          return (
            <ul key={index}>
              {!showMiniCard && (
                <li
                  className={`${className}__product`}
                  onMouseOver={handleHover}
                  onMouseLeave={handleOut}
                >
                  <Link
                    to={`/boutique/${product._category
                      .replace(/\s+/g, '-')
                      .toLowerCase()}/${product._id}`}
                    style={linkStyle}
                  >
                    <ProductCard
                      className={`${className}__product__productCard`}
                      product={product}
                      handleAddToCart={handleAddToCart}
                      descLength="100"
                    />
                  </Link>
                </li>
              )}
              {showMiniCard && (
                <li
                  className={`${className}__miniCard`}
                  onMouseOver={handleHover}
                  onMouseLeave={handleOut}
                >
                  <Link
                    to={`/boutique/${category
                      .toLowerCase()
                      .replace(/\s+/g, '-')}/${product._id}`}
                    style={linkStyle}
                  >
                    <ProductMiniCard
                      className={`${className}__miniCard`}
                      product={product}
                    />
                  </Link>
                </li>
              )}
            </ul>
          )
        })}
      </div>
    </>
  )
}
